import axios, { AxiosResponse } from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
);
export const functions = {
	getPodDocumentUrl: 'mlrc_b2b-GetPodDocumentCP',
	downloadPodDocumentUrl: 'mlrc_b2b-DownloadPODDocumentCP',
	createNotificationSettings: 'mlrc_b2b-CreateUpdateCustomerNotificationSettingsCP',
	getNotificationSettings: 'mlrc_b2b-GetCustomerNotificationSettingsCP',
	editNotificationSettings: 'mlrc_b2b-CreateUpdateCustomerNotificationSettingsCP',
	deleteNotificationRecord: 'mlrc_b2b-DeleteCustomerNotificationSettingsCP',
	updateDeliveryInstructions: 'mlrc_b2b-UpdateDeliveryNotesCP',
	updateFeedBack: 'mlrc_b2b-UpdateCustomerFeedbackCP',
	retrieveRouteLegData: 'mlrc_b2b-RetrieveRouteLegDataCP',
	retrieveRouteLeg: 'mlrc_b2b-RetrieveRouteLegCP',
};
// // Users

export const apiPost: (
	functionName: string,
	payload: { [key: string]: any },
) => Promise<AxiosResponse<any, any>> = async (functionName, payload) => {
	let url = process.env.REACT_APP_CF_URL + functionName;

	const response = await axios.post(url, payload);
	return response;
};
export const apiGet: (
	functionName: string,
	query: string,
) => Promise<AxiosResponse<any, any>> = async (functionName, query) => {
	let url = process.env.REACT_APP_CF_URL + functionName + query;
	const response = await axios.get(url);
	return response;
};

export default axiosInstance;
