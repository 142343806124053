/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, FC, ReactNode, useCallback, useEffect, useReducer } from 'react';

import {
	doc,
	getDoc,
	Timestamp,
	GeoPoint,
	DocumentData,
	DocumentReference,
	collectionGroup,
	query,
	where,
	getDocs,
} from 'firebase/firestore';

import useAuth from '../hooks/useAuth';

import { RCStatusEnum } from '../components/CustomBadge';
import { firebaseConfig } from '../config';
import Delivery from '../classes/Delivery';
import Order from '../classes/Order';
import Pickup from '../classes/Pickup';
import { Pod } from '../classes/Pod';
import { apiPost, functions } from '../helpers/axios';
import showNotification from '../components/extras/showNotification';

import { v4 as randomUUID } from 'uuid';

// initializeApp(firebaseConfig);
// const db = getFirestore();

const DELIVERIES = 'deliveries';
const ORDERS = 'orders';
const PICKUPS = 'pickups';
export const SETTINGS = 'settings';
export const ADDSTORE = 'ADDSTORE';
const ROUTES = 'ROUTES';
const ROUTELEGS = 'ROUTELEGS';
export const CLEAR = 'CLEAR';
export const SETCUSTEMAIL = 'SETCUSTEMAIL';

export const actionTypes = {
	DELIVERY: 'DELIVERY',
	DELIVERIES: 'DELIVERIES',
	PICKUPS: 'PICKUPS',
	PICKUP: 'PICKUP',
	ORDERS: 'ORDERS',
	ORDER: 'ORDER',
	SETTINGS: 'SETTINGS',
	ADDSTORE: 'ADDSTORE',
	ROUTES: 'ROUTES',
	ROUTELEGS: 'ROUTELEGS',
	CLEAR: 'CLEAR',
	SETCUSTEMAIL: 'SETCUSTEMAIL',
};

type DeliveryType = {
	delivered_by: string;
	delivery_datetime: Timestamp;
	delivery_id: string;
	delivery_location: GeoPoint;
	driver_id: string;
	customer_id: string;
	customer_name: string;
	route_leg_id: string;
	line_count: number;
	order_count: number;

	route_id: string;
	total_packages: number;
	route_datetime: Timestamp;

	status: RCStatusEnum;
};

type PickupType = {
	pickup_by: string;
	pickup_datetime: Timestamp;
	pickup_id: string;
	pickup_location: GeoPoint;
	driver_id: string;
	route_leg_id: string;
	line_count: number;
	order_count: number;
	total_packages: number;
	customer_id: string;
	customer_name: string;
	route_id: string;
	route_datetime: Timestamp;
	status: RCStatusEnum;
};
type OrderType = {
	id: string;
	delivery_pickup_datetime: Timestamp;
	route_datetime: Timestamp;
	order_no: string;
	order_name: string;
	order_type: string;
	ref_no: string;
	req_no: string;
	route_id: string;
	route_leg_id: string;
	customer_name: string;
	customer_id: string;
	order_count: number;
	status: RCStatusEnum;
};
export type RCTypeInfo = {
	DELIVERY: DeliveryType;
	ORDER: OrderType;
	PICKUP: PickupType;
};
interface ProviderProps {
	children?: ReactNode;
}
export type DataState<Type> = {
	loading: boolean;
	error?: any;
	data: Type;
};
type UserData = {
	deliveries: DataState<Delivery[]>;
	orders: DataState<Order[]>;
	pickups: DataState<Pickup[]>;
	routes: { [key: string]: { route_id: string; route_datetime: Timestamp } };
	route_legs: {
		[key: string]: { route_leg_id: string; total_packages: number };
	};
	logo: string;
	smsActive?: boolean;
	settings: any;
	stores: {
		[id: string]: { [key: string]: string };
	};
};
const initialState = {
	deliveries: {
		loading: true,
		data: [],
	},
	orders: {
		loading: true,
		data: [],
	},
	pickups: {
		loading: true,
		data: [],
	},
	routes: {},
	route_legs: {},
	logo: '',
	smsActive: false,
	settings: { loading: true, data: {}, notifications: [], update_id: '' },
	stores: {},
};
type UserDataPayload = {
	deliveries?: DataState<Delivery[]>;
	orders?: DataState<Order[]>;
	pickups?: DataState<Pickup[]>;
	routes?: { [key: string]: { route_id: string; route_datetime: Timestamp } };
	route_legs?: {
		[key: string]: { route_leg_id: string; total_packages: number };
	};
	logo?: string;
	smsActive?: boolean;
	settings?: {
		loading: boolean;
		data?: { [key: string]: any } | undefined | null;
		notifications?: { [key: string]: string | number | boolean }[];
	};
	route?: { route_id: string; route_datetime: Timestamp };
	route_leg?: { route_leg_id: string; total_packages: number };

	store?: {
		id: string;
		name?: string;
	};
	delivery_id?: string;
	pickup_id?: string;
	order_no?: string;
	deliveryUpdate?: Delivery;
	pickupUpdate?: Pickup;
	orderUpdate?: Order;
};

const reducer: (state: UserData, action: { type: string; payload: UserDataPayload }) => UserData = (
	state,
	action,
) => {
	const {
		deliveries,
		orders,
		pickups,
		logo,
		smsActive,
		settings,
		route,
		store,
		delivery_id,
		pickup_id,
		order_no,
		deliveryUpdate,
		pickupUpdate,
		orderUpdate,
	} = action.payload;
	const routeLeg = action.payload.route_leg;
	switch (action.type) {
		case actionTypes.DELIVERIES:
			return {
				...state,
				deliveries: deliveries as DataState<Delivery[]>,
			};
		case actionTypes.DELIVERY:
			if (deliveryUpdate) {
				const index = state.deliveries?.data.findIndex(
					(delivery) => delivery.delivery_id === deliveryUpdate?.delivery_id,
				);

				if (index >= 0) {
					state.deliveries.data[index] = deliveryUpdate;
				}
			}

			return {
				...state,
				deliveries: { ...state.deliveries, data: state.deliveries.data },
			};

		case actionTypes.ORDERS:
			return {
				...state,
				orders: orders as DataState<Order[]>,
			};
		case actionTypes.ORDER:
			if (orderUpdate) {
				const index = state.orders?.data.findIndex(
					(order) => order.order_no === orderUpdate?.order_no,
				);

				if (index >= 0) {
					state.orders.data[index] = orderUpdate;
				}
			}

			return {
				...state,
				orders: { ...state.orders, data: state.orders.data },
			};

		case actionTypes.PICKUPS:
			return {
				...state,
				pickups: pickups as DataState<Pickup[]>,
			};
		case actionTypes.PICKUP:
			if (pickupUpdate) {
				const index = state.pickups?.data.findIndex(
					(pickup) => pickup.pickup_id === pickupUpdate?.pickup_id,
				);

				if (index >= 0) {
					state.pickups.data[index] = pickupUpdate;
				}
			}

			return {
				...state,
				pickups: { ...state.pickups, data: state.pickups.data },
			};

		case actionTypes.ROUTES:
			state.routes[route?.route_id as string] = {
				...state.routes[route?.route_id as string],
				...route,
			};
			return {
				...state,
			};

		case actionTypes.ROUTELEGS:
			state.route_legs[routeLeg?.route_leg_id as string] = {
				...state.route_legs[routeLeg?.route_leg_id as string],
				...routeLeg,
			};
			return {
				...state,
			};
		case actionTypes.SETTINGS:
			return {
				...state,
				settings,

				update_id: randomUUID(),
			};
		case actionTypes.ADDSTORE:
			if (!store?.id || !store.name) {
				return state;
			}
			return {
				...state,
				stores: { ...state.stores, [store.id]: { name: store.name } },
			};
		case actionTypes.CLEAR:
			return {
				...initialState,
			};

		case 'logo':
			if (logo && logo?.length) {
				window.localStorage.setItem('logo', JSON.stringify(logo));
				return {
					...state,
					logo,
					smsActive,
				};
			}

			break;

		default:
			return state;
	}

	return state;
};

export interface UserDataContext {
	userData: UserData;
	userDataDispatch: React.Dispatch<{
		type: string;
		payload: UserDataPayload;
	}>;

	getCredentials: () => Promise<{
		customerData: DocumentData | undefined;
		customerRef: DocumentReference<DocumentData>;
	}>;
	fetchOrders: (store_id?: string) => Promise<void>;
	fetchDeliveries: (store_id?: string) => Promise<void>;
	fetchPickups: (store_id?: string) => Promise<void>;
	// getSettings: () => Promise<void>;
	fetchUpdateRouteDatetime: (driver_id: string, route_id: string) => Promise<Timestamp>;
	fetchPodDetails: ({
		driver_id,
		route_id,
		route_leg_id,
		tracking_ref,
		type,
	}: {
		driver_id: string;
		route_id: string;
		route_leg_id: string;
		tracking_ref: string;
		type: string;
	}) => Promise<
		| {
				logo: string;
				error?: undefined;
		  }
		| {
				error: unknown;
		  }
	>;
	fetchNumberofPackages: (
		driver_id: string,
		route_id: string,
		route_leg_id: string,
	) => Promise<number>;
	setNotificationDestinations: () => Promise<void>;
	addNotificationDestination: (destinationRecord: {
		[key: string]: string | number | boolean;
	}) => void;
}
const DataContext = createContext<UserDataContext | null>(null);

const DataProvider: FC<ProviderProps> = ({ children }) => {
	const { user, tenantDb, isInitialized, isAuthenticated } = useAuth();

	const [state, dispatch] = useReducer(reducer, initialState);
	const tenantId = firebaseConfig.projectId;

	const getCredentials = useCallback(async () => {
		let logoUri = process.env.REACT_APP_LOGO_URL;

		dispatch({
			type: 'logo',
			payload: {
				logo: logoUri,
				smsActive: true,
			},
		});
		const customerId = user.customer_id;

		const customerRef = doc(tenantDb, 'mlrc-portal', customerId as string);
		const customerSnapShot = await getDoc(customerRef);
		const customerData = customerSnapShot.data();

		return {
			customerData,
			customerRef,
			customerId,
		};
	}, [tenantDb, tenantId, user.customer_id]);

	useEffect(() => {
		if (isAuthenticated && user.customer_id?.length) {
			fetchOrders();
			setNotificationDestinations();
			fetchDeliveries();
			fetchPickups();
		}
	}, [isInitialized, isAuthenticated, user.email, user.customer_id]);

	const fetchDeliveries = async () => {
		if (!user || state.deliveries?.data?.length) {
			return;
		}

		try {
			const customerRef = (await getCredentials()).customerRef;
			if (!customerRef) {
				throw new Error('ref Not found');
			}
			let deliveries = await Delivery.fetchCustomerDeliveries(customerRef);

			dispatch({
				type: actionTypes.DELIVERIES,
				payload: {
					deliveries: {
						loading: false,
						data: deliveries.sort(
							(a, b) => b.delivery_datetime?.seconds - a.delivery_datetime?.seconds,
						),
						error: null,
					},
				},
			});
		} catch (error) {
			dispatch({
				type: actionTypes.DELIVERIES,
				payload: {
					deliveries: {
						loading: false,
						data: [],
						error,
					},
				},
			});
		}
	};
	const fetchOrders = async () => {
		if (!user || state.orders?.data?.length) {
			return;
		}

		try {
			const customerRef = (await getCredentials()).customerRef;

			if (!customerRef) {
				throw new Error('ref Not found');
			}

			const orders: Order[] = await Order.fetchCustomerOrders(customerRef);

			dispatch({
				type: actionTypes.ORDERS,

				payload: {
					orders: {
						loading: false,
						data: orders.sort(
							(a, b) =>
								b.delivery_pickup_datetime?.seconds -
								a.delivery_pickup_datetime?.seconds,
						),
						error: null,
					},
				},
			});
		} catch (error) {
			dispatch({
				type: actionTypes.ORDERS,
				payload: {
					orders: {
						loading: false,
						data: [],
						error,
					},
				},
			});
		}
	};
	const fetchPickups = async () => {
		if (!user || state.pickups?.data?.length) {
			return;
		}

		try {
			const customerRef = (await getCredentials()).customerRef;

			if (!customerRef) {
				throw new Error('ref Not found');
			}

			let pickups = await Pickup.fetchCustomerPickups(customerRef);

			dispatch({
				type: actionTypes.PICKUPS,
				payload: {
					pickups: {
						loading: false,
						data: pickups.sort(
							(a, b) => b.pickup_datetime?.seconds - a.pickup_datetime?.seconds,
						),
					},
				},
			});
		} catch (error) {
			dispatch({
				type: actionTypes.PICKUPS,
				payload: {
					pickups: {
						loading: false,
						data: [],
						error,
					},
				},
			});
		}
	};
	const fetchUpdateRouteDatetime = async (driver_id: string, route_id: string) => {
		try {
			let routeDatetime = state.routes[route_id]?.route_datetime;
			if (!routeDatetime?.seconds) {
				const routeRef = doc(
					tenantDb,
					'mlrc-routes',
					driver_id.trim(),
					'routes',
					route_id.trim(),
				);
				const routeSnapShot = await getDoc(routeRef);
				if (!routeSnapShot.exists()) {
					throw new Error('not found');
				}
				routeDatetime = routeSnapShot.data()?.route_datetime as Timestamp;
				dispatch({
					type: actionTypes.ROUTES,
					payload: { route: { route_id, route_datetime: routeDatetime } },
				});
			}

			return routeDatetime;
		} catch (error) {
			return new Timestamp(0, 0);
		}
	};
	const fetchEstimatedDatetime = async (trackingRef: string) => {
		try {
			const shipmentRef = query(
				collectionGroup(tenantDb, 'route-leg-data'),
				where('tracking_ref', '==', trackingRef),
			);

			const shipmentsSnapshot = await getDocs(shipmentRef);
			if (!shipmentsSnapshot.docs.length) {
				throw new Error('not found');
			}
			return shipmentsSnapshot.docs[0].data()?.estimated_completion as Timestamp;
		} catch (error) {
			return new Timestamp(0, 0);
		}
	};
	const fetchUpdateRouteDatetimeforOrders = async (
		route_id: string,
		delivery_pickup_id: string,
		type: string,
	) => {
		try {
			let routeDatetime = state.routes[route_id]?.route_datetime;
			const { customerRef } = await getCredentials();
			const orderType = type.toLowerCase();

			if (!routeDatetime?.seconds) {
				const deliveryPickupSnapshot = await getDoc(
					doc(
						customerRef,
						orderType === 'delivery' ? 'deliveries' : type + 's',
						delivery_pickup_id,
					),
				);
				const deliveryPickup = deliveryPickupSnapshot.data();
				const routeRef = doc(
					tenantDb,
					'mlrc-routes',
					deliveryPickup?.driver_id.trim(),
					'routes',
					route_id.trim(),
				);
				const routeSnapShot = await getDoc(routeRef);
				if (!routeSnapShot.exists()) {
					throw new Error('not found');
				}
				routeDatetime = routeSnapShot.data()?.route_datetime as Timestamp;
				dispatch({
					type: actionTypes.ROUTES,
					payload: { route: { route_id, route_datetime: routeDatetime } },
				});
			}

			return routeDatetime;
		} catch (error) {
			return new Timestamp(0, 0);
		}
	};

	const fetchNumberofPackages = async (
		driver_id: string,
		route_id: string,
		route_leg_id: string,
	) => {
		try {
			let totalPackages = state.route_legs[route_leg_id]?.total_packages;
			if (!totalPackages) {
				const routeRef = doc(
					tenantDb,
					'mlrc-routes',
					driver_id.trim(),
					'routes',
					route_id.trim(),
					'route-legs',
					route_leg_id.trim(),
				);
				const routeLegSnapshot = await getDoc(routeRef);
				if (!routeLegSnapshot.exists()) {
					throw new Error('not found');
				}
				totalPackages = routeLegSnapshot.data()?.total_packages as number;
				dispatch({
					type: actionTypes.ROUTELEGS,
					payload: {
						route_leg: { route_leg_id, total_packages: totalPackages },
					},
				});
			}

			return totalPackages;
		} catch (error) {
			return 0;
		}
	};

	const addNotificationDestination = (destinationRecord: {
		[key: string]: string | number | boolean;
	}) => {
		dispatch({
			type: actionTypes.SETTINGS,
			payload: {
				settings: {
					...state.settings,
					loading: false,
					notifications: [...state.settings.notifications, destinationRecord],
				},
			},
		});
	};
	const setNotificationDestinations = async () => {
		try {
			const response = await apiPost(functions.getNotificationSettings, {
				customer_id: user.customer_id,
			});

			dispatch({
				type: actionTypes.SETTINGS,
				payload: {
					settings: {
						...state.settings,
						loading: false,
						notifications: response.data.notifications,
					},
				},
			});
		} catch (error) {
			showNotification('Notification Destinations', 'Fetch Failed', 'danger');
			dispatch({
				type: actionTypes.SETTINGS,
				payload: {
					settings: {
						...state.settings,
						loading: false,
						error: 'an error occured',
					},
				},
			});
		}
	};
	const fetchPodDetails = useCallback(
		async ({
			driver_id,
			route_id,
			type,
			route_leg_id,
			tracking_ref,
		}: {
			driver_id: string;
			route_id: string;
			route_leg_id: string;
			tracking_ref: string;
			type: string;
		}) => {
			try {
				return {
					...(await Pod.getFirestoreData({
						db: tenantDb,
						driver_id,
						route_id,
						route_leg_id,
						tracking_ref,
					})),
					error: false,

					// logo,
				};
			} catch (error) {
				return {
					error: error,
				};
			}
		},
		[user.email],
	);
	return (
		<DataContext.Provider
			value={{
				userData: state,
				userDataDispatch: dispatch,
				getCredentials,
				fetchDeliveries,
				// getSettings,
				// getProjectData,
				fetchPodDetails,
				fetchOrders,
				fetchPickups,
				fetchNumberofPackages,
				fetchUpdateRouteDatetime,
				setNotificationDestinations,
				addNotificationDestination,
			}}>
			{children}
		</DataContext.Provider>
	);
};

export { DataContext, DataProvider };
