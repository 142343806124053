import * as React from 'react';
import { Navigate } from 'react-router-dom';
// import { DataContext } from '../../contexts/UserDataContext';

import useAuth from '../../hooks/useAuth';
import { demoPagesMenu } from '../../menu';
interface AuthGuardProps {
	children?: React.ReactNode;
}
// For routes that can only be accessed by authenticated users
const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
	const { isAuthenticated, isInitialized, user } = useAuth();

	if (isInitialized && !isAuthenticated) {
		window.localStorage.setItem('lastPath', window.location.pathname);
		return <Navigate to={demoPagesMenu.login.path} />;
	}

	return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
