import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '', element: null },
	{ path: '*', element: null },
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <DefaultAside />,
	},
	{
		path: dashboardPagesMenu.orders.path,
		element: <DefaultAside />,
	},
	{
		path: dashboardPagesMenu.deliveries.path,
		element: <DefaultAside />,
	},
	{
		path: dashboardPagesMenu.pickups.path,
		element: <DefaultAside />,
	},
	{
		path: dashboardPagesMenu.settings.path,
		element: <DefaultAside />,
	},
	{
		path: dashboardPagesMenu.stores.path,
		element: <DefaultAside />,
	},
	{
		path: '/orders/:orderNumber',
		element: <DefaultAside />,
	},
	{
		path: '/deliveries/:deliveryId',
		element: <DefaultAside />,
	},
	{
		path: '/pickups/:pickupId',
		element: <DefaultAside />,
	},
];

export default asides;
