import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
	const navigate = useNavigate();
	const currentState = Date.now();
	return (
		<div className='brand p-0'>
			<div className='brand-logo p-0 m-0'>
				<h1
					className='brand-title cursor-pointer'
					onClick={() => {
						const diff = Date.now() - currentState;
						if (diff > 120000) {
							navigate('/');
						}
					}}>
					<span className='ps-1'>
						<Logo height={60} roundedEnd alwaysLight />
					</span>
				</h1>
			</div>

			<button
				type='button'
				className='btn brand-aside-toggle bg-primary'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}
				style={asideStatus ? { padding: 2, marginLeft: '5px', marginRight: '10px' } : {}}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
