// import { Badge } from "react-bootstrap";
// import { deliveryColumns } from "../pages/tables/data";

import React, { FC } from 'react';

export enum RCStatusEnum {
	UNKNOWN = 0,
	SCHEDULED = 1,
	//   INTRANSIT = 2,
	ATTEMPTED = 4,
	DELIVERED = 5,
	INTRANSIT = 6,
	SKIPPED = 7,
	CANCELLED = 8,
	REJECTED = 9,
	PARTIAL = 10,
}
interface RCStatusInfo {
	title: {
		[key: string]: string;

		delivery: string;
		pickup: string;
		order: string;
	};
	background: '#e5a54b';
}
export enum RCType {
	DELIVERY = 'delivery',
	ORDER = 'order',
	PICKUP = 'pickup',
}
export const badgeTypes: any = {
	1: {
		title: {
			delivery: 'Scheduled',
			pickup: 'Scheduled',
			order: 'Scheduled',
		},
		background: '#e5a54b',
	},
	2: {
		title: {
			delivery: 'In Transit',
			pickup: 'In Transit',
			order: 'In Transit',
		},
		background: '#3f80ea',
	},
	3: {
		background: '#30A800',
		title: {
			delivery: 'Delivered',
			pickup: 'Completed',
			order: 'Delivered',
		},
	},
	4: {
		title: {
			delivery: 'Attempted',
			pickup: 'Attempted',
			order: 'Attempted',
		},
		background: '#800080',
	},
	5: {
		background: '#30A800',
		title: {
			delivery: 'Delivered',
			pickup: 'Completed',
			order: 'Delivered',
		},
	},
	6: {
		title: {
			delivery: 'In Transit',
			pickup: 'In Transit',
			order: 'In Transit',
		},
		background: '#3f80ea',
	},
	7: {
		title: {
			delivery: 'Skipped',
			pickup: 'Skipped',
			order: 'Skipped',
		},
		background: '#000A64',
	},
	8: {
		title: {
			delivery: 'Cancelled',
			pickup: 'Canceled',
			order: 'Canceled',
		},
		background: '#FF4949',
	},
	9: {
		background: '#FF4949',
		title: {
			delivery: 'Rejected',
			pickup: 'Rejected',
			order: 'Rejected',
		},
	},

	10: {
		background: '#845F4C',
		title: {
			delivery: 'Partial',
			pickup: 'Partial ',
			order: 'Partial',
		},
	},
};
export const getStatusEnums = (code: RCStatusEnum) => {
	if (badgeTypes[code]) {
		return badgeTypes[code] as RCStatusInfo;
	}
	return {
		title: {
			delivery: '',
			pickup: '',
			order: '',
		},
		background: '',
	} as unknown as RCStatusInfo;
};
interface CustomBadgeProps {
	type: RCType;

	code: RCStatusEnum;
	width?: string;
	className?: string;
	large?: boolean;
}
const CustomBadge: FC<CustomBadgeProps> = ({
	type = 'order',
	code,
	width = '70',
	className = '',
	large,
}) => {
	const statusDetails = getStatusEnums(code);
	return (
		<div
			className={'d-flex justify-content-center align-items-center ' + className}
			style={{
				padding: large ? '5px 7px' : '2px 5px',
				// width: 'max-content',
				width: large ? '80px' : '70px',
				fontSize: large ? '12px' : '10px',
				color: 'white',
				borderRadius: '5px',
				background: statusDetails.background,
			}}
			// bg={badgeTypes[type.toString()] ? badgeTypes[type].background : "danger"}
		>
			{/* {badgeTypes[type] ? badgeTypes[type].title : " "} */}
			{statusDetails.title[type]}
		</div>
	);
};

export default CustomBadge;
