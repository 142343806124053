import {
	DocumentData,
	DocumentReference,
	GeoPoint,
	Timestamp,
	collection,
	getDocs,
	orderBy,
	query,
	where,
} from 'firebase/firestore';
import CustomBadge, { getStatusEnums, RCStatusEnum, RCType } from '../components/CustomBadge';
import { RCTypeInfo } from '../contexts/UserDataContext';
import { apiPost, functions } from '../helpers/axios';
import moment from 'moment';

export default class Pickup {
	pickup_id = '';

	pickup_datetime = new Timestamp(0, 0);

	pickup_by = '';

	pickup_location = new GeoPoint(0, 0);

	line_count = 0;

	order_count = 0;

	route_id = '';

	driver_id = '';

	route_leg_id = '';

	customer_id = '';

	customer_name = '';

	status = 0;

	route_datetime = new Timestamp(0, 0);

	total_packages = 0;

	feedback_stars = 0;

	feedback_comments = '';

	feedback_datetime = '';

	delivery_instructions = '';

	route_status = 0;

	confirmation_images = [];

	packages = [];

	confirm_signature = '';

	updated = false;

	lines: { [key: string]: any }[] = [];

	constructor(pickup: RCTypeInfo['PICKUP']) {
		this.pickup_id = pickup.pickup_id;
		this.pickup_datetime = pickup.pickup_datetime;
		this.pickup_by = pickup.pickup_by;
		this.pickup_location = pickup.pickup_location;
		this.line_count = pickup.line_count;
		this.order_count = pickup.order_count;
		this.route_id = pickup.route_id;
		this.driver_id = pickup.driver_id;
		this.route_leg_id = pickup.route_leg_id;
		this.status = pickup.status;
		this.route_datetime = pickup.route_datetime;
		this.total_packages = pickup.total_packages;
		this.customer_id = pickup.customer_id;
		this.customer_name = pickup.customer_name;
	}

	get datetime() {
		const dateTimeStamp = this.pickup_datetime.toDate();

		const datetime = this.route_datetime
			? this.route_datetime
			: [dateTimeStamp?.toDateString(), dateTimeStamp?.toLocaleTimeString()];
		return datetime;
	}

	get data(): any {
		const status = getStatusEnums(this.status).title.delivery;

		try {
			let date;
			let time;
			date = this.pickup_datetime.seconds
				? moment(this.pickup_datetime.toDate()).format('DD MMMM, YYYY')
				: '';
			time = this.pickup_datetime.seconds
				? moment(this.pickup_datetime.toDate()).format('hh:mm A')
				: '';
			return {
				...this,
				status,
				badge: <CustomBadge type={RCType.PICKUP} code={this.status} />,
				date,
				time,
			};
		} catch (error) {
			return {
				...this,
				status,
				badge: <CustomBadge type={RCType.PICKUP} code={this.status} />,
				date: '',
				time: '',
			};
		}
	}
	updateFeedBack = async (stars: number, comments: string) => {
		await apiPost(functions.updateFeedBack, {
			tracking_ref: this.pickup_id,
			driver_id: this.driver_id,
			route_id: this.route_id,
			route_leg_id: this.route_leg_id,
			feedback_comments: comments,
			feedback_stars: stars,
			feedback_datetime: moment().toISOString(),
		});
		this.feedback_stars = stars;
		this.feedback_comments = comments;
		this.feedback_datetime = moment().toISOString();
	};
	updateDeliveryInstructions = async (instructions: string) => {
		await apiPost(functions.updateDeliveryInstructions, {
			tracking_ref: this.pickup_id,
			driver_id: this.driver_id,
			route_id: this.route_id,
			route_leg_id: this.route_leg_id,
			notes: instructions,
		});
		this.delivery_instructions = instructions;
	};

	getRouteLegData = async () => {
		const response = await apiPost(functions.retrieveRouteLegData, {
			tracking_ref: this.pickup_id,
		});

		this.delivery_instructions = response.data?.data?.driver_instructions;
		this.feedback_stars = response.data?.data?.feedback_stars || 0;
		this.feedback_comments = response.data?.data?.feedback_comments || '';
		this.feedback_datetime = moment(response.data?.data?.feedback_datetime || '').toISOString();
		this.route_status = response.data?.data?.route_status;
		this.confirmation_images = response.data?.data?.confirmation_images;
		this.packages = response.data?.data?.packages;
		this.confirm_signature = response.data?.data?.confirm_signature;
		this.updated = true;

		const completedStatuses = [
			RCStatusEnum.ATTEMPTED,
			RCStatusEnum.DELIVERED,
			RCStatusEnum.PARTIAL,
			RCStatusEnum.REJECTED,
			RCStatusEnum.SKIPPED,
		];
		const { status, estimated_completion, confirm_datetime, attempted_datetime } =
			response.data?.data || {};

		if (!completedStatuses.includes(this.status)) {
			if (!completedStatuses.includes(status)) {
				this.pickup_datetime = Timestamp.fromDate(
					moment(estimated_completion || '').toDate(),
				);
			} else if (response.data?.data?.status === RCStatusEnum.DELIVERED) {
				this.pickup_datetime = Timestamp.fromDate(moment(confirm_datetime || '').toDate());
			} else {
				this.pickup_datetime = Timestamp.fromDate(
					moment(attempted_datetime || '').toDate(),
				);
			}
			this.status = status;
		}
	};
	getRouteLeg = async () => {
		const response = await apiPost(functions.retrieveRouteLeg, {
			driver_id: this.driver_id,
			route_id: this.route_id,
			route_leg_id: this.route_leg_id,
			tracking_ref: this.pickup_id,
		});
		this.delivery_instructions = response.data?.data?.notes || '';
	};

	static fetchCustomerPickups = async (
		customerRef: DocumentReference<DocumentData, DocumentData>,
	) => {
		const pickupQuery = query(
			collection(customerRef, 'pickups'),
			where('pickup_id', '!=', 'NGWINTDATA'),
			orderBy('pickup_id', 'desc'),
		);

		const pickupsCollection = await getDocs(pickupQuery);
		return await Promise.all(
			pickupsCollection.docs.map(async (docSnapshot) => {
				const docData = docSnapshot.data();
				docData.pickup_id = docSnapshot.id;

				return new Pickup({
					...(docData as RCTypeInfo['PICKUP']),
					total_packages: docData.quantity_ordered || 0,
					route_datetime: docData.pickup_datetime,
				});
			}),
		);
	};

	updateLines = (lines: { [key: string]: any }[]) => {
		this.lines = lines;
	};
}
