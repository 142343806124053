import { Firestore, Timestamp, collection, doc, getDoc, getDocs } from 'firebase/firestore';

export class Pod {
	id = '';

	customer_id = '';

	customer_name = '';

	datetime = new Timestamp(0, 0);

	status = 0;

	recepient = '';

	location_image = 'data:image/jpeg;base64,';

	signature_image = 'data:image/jpeg;base64,';

	image = 'data:image/jpeg;base64,';

	latitude = 0.0;

	longitude = 0.0;

	street = '';

	city = '';

	state = '';

	country = '';

	zip = '';

	notes = '';

	delivered_by = '';

	manually_confirmed = false;

	skipped_reason = '';

	skipped_resason = '';

	[key: string]: any;

	firestoreFields = [
		'id',
		'vehicle_id',
		'customer_id',
		'customer_name',
		'datetime',
		'recepient',
		'location_image',
		'signature_image',
		'manually_confirmed',
		'skipped_reason',
		'image',
		'latitude',
		'longitude',
		'street',
		'city',
		'state',
		'country',
		'zip',
		'delivered_by',
		'notes',
		'id',
		'datetime',
		'recepient',
		'location_image',
		'signature_image',
		'manually_confirmed',
		'skipped_reason',
		'image',
		'latitude',
		'longitude',
		'street',
		'city',
		'state',
		'country',
		'zip',
		'notes',
		'status',
		'status',
		'orders',
		'sdi',
		'spi',
		'sdsi',
		'sdpi',
	];

	getDateTime = (timestamp: Timestamp) => {
		try {
			return timestamp?.toDate()?.toISOString();
		} catch (error: any) {
			return '';
		}
	};

	getImage = (imageString: string) => {
		try {
			return imageString?.replace('data:image/jpeg;base64,', '');
		} catch (error: any) {
			return error.message;
		}
	};

	constructor(podData: any) {
		for (var field in podData) {
			if (this.firestoreFields.includes(field) && podData[field] !== undefined) {
				this[field] = podData[field];
			}
		}
	}

	static getFirestoreData = async ({
		db,
		driver_id,
		route_id,
		route_leg_id,
		tracking_ref,
	}: {
		db: Firestore;
		driver_id: string;
		route_id: string;
		route_leg_id: string;
		tracking_ref: string;
	}) => {
		const routeLegsAddressRef = doc(
			db,
			'mlrc-routes',
			driver_id,
			'routes',
			route_id,
			'route-legs',
			route_leg_id,
			'route-leg-address',
			'address',
		);

		const routeLegRef = doc(
			db,
			'mlrc-routes',
			driver_id,
			'routes',
			route_id,
			'route-legs',
			route_leg_id,
		);
		const driverRef = doc(db, 'mlrc-routes', driver_id);
		const deliveryPickupRef = doc(
			db,
			'mlrc-routes',
			driver_id,
			'routes',
			route_id,
			'route-legs',
			route_leg_id,
			'route-leg-data',
			tracking_ref,
		);
		const imagesRef = collection(deliveryPickupRef, 'confirmation-images');

		const [deliveryPickup, address, routeLegDetails, driverDetails, routeDetails, images] =
			await Promise.all([
				(async () => {
					const deliveryPickupSnapshot = await getDoc(deliveryPickupRef);
					return deliveryPickupSnapshot.data();
				})(),
				(async () => {
					const routeLegAddressSnapShot = await getDoc(routeLegsAddressRef);
					return routeLegAddressSnapShot.data() || {};
				})(),
				(async () => {
					const routeLegDetailsSnapShot = await getDoc(routeLegRef);
					return routeLegDetailsSnapShot.data() || {};
				})(),

				(async () => {
					const driverDetailsSnapshot = await getDoc(driverRef);
					return driverDetailsSnapshot.data() || {};
				})(),
				(async () => {
					const routeDetailsSnapShot = await getDoc(
						doc(db, 'mlrc-routes', driver_id, 'routes', route_id),
					);
					return routeDetailsSnapShot.data() || {};
				})(),
				(async () => {
					const imagesSnapshot = await getDocs(imagesRef);
					return imagesSnapshot.docs.map((docSnapshot) => docSnapshot.data()?.image);
				})(),
			]);

		return {
			...deliveryPickup,
			...address,
			...routeLegDetails,
			...driverDetails,
			...routeDetails,
			images,
		};
	};
}
