export const auth0Config = {
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
	userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
	clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
