import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	pageLayoutTypesPagesMenu,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: demoPagesMenu.page404.path, element: null },
	{ path: demoPagesMenu.knowledge.subMenu.grid.path, element: null },
	{ path: '', element: null },
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{ path: dashboardPagesMenu.orders.path, element: <DashboardHeader /> },
	{ path: dashboardPagesMenu.deliveries.path, element: <DashboardHeader /> },
	{ path: dashboardPagesMenu.pickups.path, element: <DashboardHeader /> },
	{ path: dashboardPagesMenu.settings.path, element: <DashboardHeader /> },
	{ path: dashboardPagesMenu.stores.path, element: <DashboardHeader /> },

	// {
	// 	path: `*`,
	// 	element: <DefaultHeader />,
	// },
	{
		path: `*`,
		element: null,
	},

	{
		path: '/orders/:orderNumber',
		element: <DashboardHeader />,
	},
	{
		path: '/deliveries/:deliveryId',
		element: <DashboardHeader />,
	},
	{
		path: '/pickups/:pickupId',
		element: <DashboardHeader />,
	},
];

export default headers;
