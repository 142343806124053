import React, { lazy } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import AuthGuard from '../components/guards/AuthGuard';
import GuestGuard from '../components/guards/GuestGuard';
import { dashboardPagesMenu, demoPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	ORDERS: lazy(() => import('../pages/Orders')),
	DELIVERIES: lazy(() => import('../pages/Deliveries')),
	SETTINGS: lazy(() => import('../pages/Settings')),
	PICKUPS: lazy(() => import('../pages/Pickups')),
	STORES: lazy(() => import('../pages/Stores')),
};
const DETAILS = {
	ORDERDETAILS: lazy(() => import('../pages/Orders/OrderDetailsPage')),
	DELIVERYDETAILS: lazy(() => import('../pages/Deliveries/DeliveryDetailsPage')),
	PICKUPDETAILS: lazy(() => import('../pages/Pickups/PickupDetailsPage')),
	PICKUPS: lazy(() => import('../pages/Pickups')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: '',
		element: <Navigate to={demoPagesMenu.login.path} replace />,
	},
	{
		path: dashboardPagesMenu.dashboard.path,
		element: (
			<AuthGuard>
				<LANDING.DASHBOARD />
			</AuthGuard>
		),
	},
	{
		path: dashboardPagesMenu.stores.path,
		element: (
			<AuthGuard>
				<LANDING.STORES />
			</AuthGuard>
		),
	},
	{
		path: dashboardPagesMenu.orders.path,
		element: (
			<AuthGuard>
				<LANDING.ORDERS />
			</AuthGuard>
		),
	},
	{
		path: dashboardPagesMenu.deliveries.path,
		element: (
			<AuthGuard>
				<LANDING.DELIVERIES />
			</AuthGuard>
		),
	},
	{
		path: dashboardPagesMenu.pickups.path,
		element: (
			<AuthGuard>
				<LANDING.PICKUPS />
			</AuthGuard>
		),
	},
	{
		path: dashboardPagesMenu.settings.path,
		element: (
			<AuthGuard>
				<LANDING.SETTINGS />
			</AuthGuard>
		),
	},
	{
		path: '/orders/:orderNumber',
		element: (
			<AuthGuard>
				<DETAILS.ORDERDETAILS />
			</AuthGuard>
		),
	},
	{
		path: '/deliveries/:deliveryId',
		element: (
			<AuthGuard>
				<DETAILS.DELIVERYDETAILS />
			</AuthGuard>
		),
	},
	{
		path: '/pickups/:pickupId',
		element: (
			<AuthGuard>
				<DETAILS.PICKUPDETAILS />
			</AuthGuard>
		),
	},
	/**
	 * Auth Page
	 */
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: (
			<GuestGuard>
				<Login />
			</GuestGuard>
		),
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},
];
const contents = [...presentation];

export default contents;
