/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import Spinner from '../../../components/bootstrap/Spinner';
import useAuth from '../../../hooks/useAuth';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h3 fw-bold mt-5 text-primary'>
				RouteCTRL Customer Portal
			</div>
			{/* <div className='text-center h4 fw-bold mt-2'>Customer Portal</div> */}
			<div className='text-center h4 text-primary mb-5'>Sign in to continue</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const [singUpStatus] = useState<boolean>(!!isSignUp);

	const { signIn } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: window.localStorage.getItem('userEmail') || '',
			loginPassword: '',
		},

		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			setIsLoading(true);

			try {
				await signIn(values.loginUsername, values.loginPassword, true);
			} catch (error: any) {
				switch (error.code) {
					case 'auth/wrong-password':
						formik.setFieldError('loginPassword', 'Wrong Password');
						// formik.setFieldError('loginUsername', ' ');
						break;
					case 'auth/too-many-requests':
						formik.setFieldError('loginPassword', ' ');
						formik.setFieldError(
							'loginUsername',
							'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
						);
						break;
					case 'auth/network-request-failed':
						formik.setFieldError(
							'loginUsername',
							'Login Failed, Check your network connection and try again',
						);
						formik.setFieldError('loginPassword', ' ');
						break;
					case 'auth/user-not-found':
						formik.setFieldError('loginUsername', 'User not found');
						formik.setFieldError('loginPassword', ' ');
						break;
					default:
						formik.setFieldError(
							'loginPassword',
							error?.message?.replace('Firebase:', ' '),
						);

						break;
				}
			}

			setIsLoading(false);
		},
	});

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames('bg-white')}>
			<Page className='p-0 '>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark bg-white' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5 text-white'>
									<Logo width={300} alwaysLight />
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									<>
										<div className='col-12'>
											<FormGroup
												id='loginUsername'
												isFloating
												label='Your email'
												className='mb-4'>
												<Input
													autoComplete='username'
													value={formik.values.loginUsername?.toLocaleLowerCase()}
													isTouched={formik.touched.loginUsername}
													invalidFeedback={formik.errors.loginUsername}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													style={{ fontSize: '20px' }}
													type='email'
													required
													className='bg-white text-dark'
												/>
											</FormGroup>

											<FormGroup
												id='loginPassword'
												isFloating
												label='Password'
												style={{
													color: 'black',
												}}>
												<Input
													type='password'
													autoComplete='current-password'
													value={formik.values.loginPassword}
													isTouched={formik.touched.loginPassword}
													invalidFeedback={formik.errors.loginPassword}
													validFeedback='Looks good!'
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															formik.handleSubmit();
														}
													}}
													onBlur={formik.handleBlur}
													required
													aria-autocomplete='both'
													className='bg-white'
													style={{
														color: 'black',
													}}
												/>
											</FormGroup>
										</div>
										<div className='col-12 mb-4 mt-4'>
											<Button
												color='warning'
												className='w-100 py-3'
												isDisable={
													!formik.values.loginPassword || isLoading
												}
												onClick={formik.handleSubmit}>
												Login
												{isLoading && (
													<Spinner
														isSmall
														inButton
														isGrow
														className='ms-3'
													/>
												)}
											</Button>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
						<div className='text-center '>
							<a
								href='https://www.marklite.com/privacy-policy/'
								className={classNames('text-decoration-none me-3', 'text-dark')}
								target='_blank'
								rel='noopener noreferrer'>
								Privacy policy
							</a>
							<a
								href='https://www.marklite.com/terms-conditions/'
								className={classNames(
									'link-dark text-decoration-none',
									'text-dark',
								)}
								target='_blank'
								rel='noopener noreferrer'>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
